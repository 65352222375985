// extracted by mini-css-extract-plugin
export const waitingGrid = "waitingSection-module--waitingGrid--1Hj4n";
export const waitingBtn = "waitingSection-module--waitingBtn--3ERIO";
export const waitingBtnImg = "waitingSection-module--waitingBtnImg--ymYN3";
export const colorImg = "waitingSection-module--colorImg--3Uj3a";
export const discolorImg = "waitingSection-module--discolorImg--3DO2A";
export const waitingBtnEffect = "waitingSection-module--waitingBtnEffect--giyuP";
export const waitingBtnTitle = "waitingSection-module--waitingBtnTitle--3Yjqp";
export const waitingBtnText = "waitingSection-module--waitingBtnText--3nAJu";
export const waitingBtnTextBG = "waitingSection-module--waitingBtnTextBG--3c1aK";
export const slidedown = "waitingSection-module--slidedown--19WPV";
export const slideup = "waitingSection-module--slideup--32E9l";