// extracted by mini-css-extract-plugin
export const animatedServices = "index-module--animatedServices--3y03c";
export const heroContainer = "index-module--heroContainer--mqxtb";
export const heroImgContainer = "index-module--heroImgContainer--3UW1H";
export const heroImgOverlay = "index-module--heroImgOverlay--q84z7";
export const heroImg = "index-module--heroImg--3nx1p";
export const heroWideImg = "index-module--heroWideImg--3ztnq";
export const heroBtn = "index-module--heroBtn--2d5X1";
export const sectionContainer = "index-module--sectionContainer--28zkm";
export const projectsContainer = "index-module--projectsContainer--1j6zA";
export const projectsGrid = "index-module--projectsGrid--2vFK-";
export const projectCard = "index-module--projectCard--3QBlT";
export const projectImg = "index-module--projectImg--3oLn4";
export const projectTitle = "index-module--projectTitle--2mmcC";
export const projectDescription = "index-module--projectDescription--3bA5i";
export const projectFiltersContainer = "index-module--projectFiltersContainer--3GfAs";
export const projectFilterText = "index-module--projectFilterText--34TuH";
export const projectFilterTextAutomotive = "index-module--projectFilterTextAutomotive--1Ye-f";
export const reviewsGrid = "index-module--reviewsGrid--RHhFv";
export const reviewCard = "index-module--reviewCard--3JIkO";
export const reviewText = "index-module--reviewText--3ZhWP";